import { Skeleton } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSettings } from "../../../actions/actions";
import { plainTranslate } from '../../common/translate';
import SettingsMenu from '../../sidebar/settings-menu';
import OpenSection from './../openSection.jsx';
import Stages from './../stages.jsx';

class DealSettingsPage extends Component {

    constructor(props) {
    super(props);
            this.state = {
            showMessage: false,
            ready: false
            };
            this.allSettings = [
                { value: "reserved", name: 'Quantity reserved?', text: 'Select whether you reserve quantity on stock'},
            ];
            this.updateStages = this.updateStages.bind(this);
    }

    componentDidMount() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/deals/settings/deal-settings', 'dealSettings')
        .then(res => {            
            this.setState({ready: true})
        })
        if (this.props.title) {
            let titleArr = this.props.title;
            titleArr.map((item, key) => titleArr[key] = plainTranslate(this.props.settings.locale, item));
            document.title = titleArr.join(' ');
        }
    }
    
    updateStages() {
        this.props.getSettings('/' + this.props.match.params.db + '/api/deals/settings/deal-settings', 'dealSettings');
    }

     render() {
        return (
                <div>
                    <SettingsMenu
                       location = {this.props.location.pathname} db = {this.props.match.params.db} />
                    <form name="settings_expense" className="cmxform form-horizontal bucket-form ng-pristine ng-valid">
                       <div className="row m-bot15">
                          <div className="col-lg-12">
                             <section className="panel">
                                <div className="panel-body">
                                   <div className="settings">
                                        <OpenSection
                                                label={plainTranslate(this.props.settings.locale, 'Deal form type')}
                                                description={plainTranslate(this.props.settings.locale, "Specify different types of documents for deals")}
                                                link={"/" + this.props.match.params.db + "/form-types-v2"}
                                        />
                                        <hr/>
                                        <OpenSection
                                                label={plainTranslate(this.props.settings.locale, 'Disable prices changes')}
                                                description={plainTranslate(this.props.settings.locale, "Disable prices changes for deal roles")}
                                                component={'multi_select'}
                                                value ={this.props.settings.dealSettings}
                                                type={'dealSettings'}
                                                saveRoute={'/' + this.props.match.params.db + '/api/deals/save/settings/individual'}
                                                setting={'dealsPricingRoles'}
                                        />
                                        <hr/>
                                        <div className="col-sm-12 column-centered">
                                         {this.state.ready ?
                                           <Stages
                                                saveRoute={'/' + this.props.match.params.db + '/api/stages/save/settings/data'}
                                                type={'dealSettings'}
                                                workflow={"deal"}
                                                value={this.props.settings.dealSettings}
                                                settings={this.props.settings}
                                                fields={this.allSettings}
                                                setting = {'stages'}
                                                updateStages={this.updateStages}
                                                configuration = {true}
                                                roles={'roles'}
                                            />
                                            :
                                            <Skeleton active />}
                                        </div>
                                   </div>  
                                </div>
                             </section>
                          </div>
                       </div>
                    </form>
                </div>
                );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
});
const mapDispatchToProps = dispatch => ({
   getSettings: (path, type) =>
            dispatch(fetchSettings(path, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DealSettingsPage);
