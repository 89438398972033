import React from 'react';
import { connect } from 'react-redux';
import client from '../../../actions/client';
import showAToast from '../../common/showAToast';
import { plainTranslate } from "../../common/translate";

class Number extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: ''
        };

        this.onChange = this.onChange.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleRecalculatePrice = this.handleRecalculatePrice.bind(this);
    }

    componentDidMount() {        
        this.setState({
            value: this.props.input.value !== undefined ? this.numberScale(this.props.input.value, this.props.field.scale) : '',
            calculateValue: this.props.input.value
        });

        var allElements = document.getElementsByTagName("label");
        let price = null;
        let discountPercent= null;
        let minAmount = null;
        let minQty = null;

        for(let i = 0; i < allElements.length; i++){
            if(allElements[i].textContent == 'Price'){
                price = allElements[i];
            }
            if(allElements[i].textContent == 'Discount Percent'){
                discountPercent = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Quantity'){
                minQty = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Amount'){
                minAmount = allElements[i];
            }
        }

        if(price && discountPercent && minQty && minAmount){
            let priceInput = price.nextSibling.getElementsByTagName("input");
            let discountPercentInput = discountPercent.nextSibling.getElementsByTagName("input");
            let minAmountInput = minAmount.nextSibling.getElementsByTagName("input");
            let minQtyInput = minQty.nextSibling.getElementsByTagName("input");

            if(priceInput[0].value.length > 0){
                discountPercentInput[0].disabled=true;
            }else{
                discountPercentInput[0].disabled=false;
            }
            if(discountPercentInput[0].value.length > 0){
                priceInput[0].disabled=true;
            }else{
                 priceInput[0].disabled=false;
            }
            if(minAmountInput[0].value.length > 0){
                minQtyInput[0].disabled=true;
            }else{
                 minQtyInput[0].disabled=false;
            }
            if(minQtyInput[0].value.length > 0){
                minAmountInput[0].disabled=true;
            }else{
                 minAmountInput[0].disabled=false;
            }

        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.input.value !== this.props.input.value) {
            this.setState({
                value: this.props.input.value !== undefined ? this.props.input.value : ''
            }, () => this.props.input.onChange(this.props.input.value));

        }
    }

    numberScale(number, scale) {
        let float = parseFloat(number);
        float = float.toFixed(scale ? scale : 2);

        return float;
    }

    onChange(e) {
        var allElements = document.getElementsByTagName("label");
        let price = null;
        let discountPercent= null;
        let minAmount = null;
        let minQty = null;

        for(let i = 0; i < allElements.length; i++){
            if(allElements[i].textContent == 'Price'){
                price = allElements[i];
            }
            if(allElements[i].textContent == 'Discount Percent'){
                discountPercent = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Quantity'){
                minQty = allElements[i];
            }
            if(allElements[i].textContent == 'Minimum Amount'){
                minAmount = allElements[i];
            }
        }

        if(price && discountPercent && minQty && minAmount){
            let priceInput = price.nextSibling.getElementsByTagName("input");
            let discountPercentInput = discountPercent.nextSibling.getElementsByTagName("input");
            let minAmountInput = minAmount.nextSibling.getElementsByTagName("input");
            let minQtyInput = minQty.nextSibling.getElementsByTagName("input");

            if(priceInput[0].value.length > 0){
                discountPercentInput[0].disabled=true;
            }else{
                discountPercentInput[0].disabled=false;
            }
            if(discountPercentInput[0].value.length > 0){
                priceInput[0].disabled=true;
            }else{
                 priceInput[0].disabled=false;
            }
            if(minAmountInput[0].value.length > 0){
                minQtyInput[0].disabled=true;
            }else{
                 minQtyInput[0].disabled=false;
            }
            if(minQtyInput[0].value.length > 0){
                minAmountInput[0].disabled=true;
            }else{
                 minAmountInput[0].disabled=false;
            }

        }

        //if(price && discountPercent && minQty && minAmount){
            if(e.target.value === '') {
                if(this.state.value.toString().length === 1 && this.state.value !== 0) {
                    this.setState({value: null});
                    this.props.input.onChange(null);
                }
                //return;
            }
/*        }else{
            if(e.target.value === '') {
                if(this.state.value.toString().length === 1 && this.state.value !== 0) {
                    this.setState({value: 0});
                    this.props.input.onChange(0);
                }
                //return;
            }
        }*/
        let value = null;

        if(e.target.value === ''){
             value = null;
        }else{
            value = parseFloat(e.target.value);

            if (isNaN(value)) {
                return;
            }
        }

        this.setState({
            value: value,
            calculateValue: value
        });
        let line='';
        //check if onChangeField and change value of this field
        if (this.props.field.hasOwnProperty('onChangeField')) {
            let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
            line = (this.props.input.name).substring(0, ind);
            let numb = (line).match(/^(\D*)(\d+)/)[2];
            if (this.props.formData.form.initializeFromState2.values.lines[numb][this.props.field.onChangeField] != ''
                    && this.props.formData.form.initializeFromState2.values.lines[numb][this.props.field.onChangeField] != 0
                    && this.props.formData.form.initializeFromState2.values.lines[numb][this.props.field.onChangeField] != null) {
                this.props.change(line+this.props.field.onChangeField, value);
            }
        }
        this.props.input.onChange(value);
        const rules = this.props.field.rules;
        if (rules) {
            let min = 'min' in rules ? rules.min : '';
            let max = 'max' in rules ? rules.max : '';
            if (value > max) {
                this.props.input.onChange(max); 
            }
            if (value < min) {
                this.props.input.onChange(min); 
            }
        }
        
        if (this.props.field.hasOwnProperty('connectedFields')) { 
            let addVal = {};
            let jsonData = [];
            if (this.props.field.connectedFields.hasOwnProperty('dependency')) {
                this.props.field.connectedFields.dependency.map(field => {
                    if (field == 'itId') {
                        let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];
                        addVal['itId'] = this.props.formData.form.initializeFromState2.values.lines[numb]['itId'];
                    } else {
                        addVal[field] = this.props.formData.form.initializeFromState2.values[field];
                    }
                })
            }

            if (this.props.field.connectedFields.isLine) {
                   let ind = (this.props.input.name).indexOf('.'+this.props.field.id);
                   line = (this.props.input.name).substring(0, ind);
                   addVal['pos'] = line;
                   if (this.props.field.connectedFields.hasOwnProperty('lineDependency')) {
                        let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];
                        this.props.field.connectedFields.lineDependency.map(field => {
                             addVal[field] = this.props.formData.form.initializeFromState2.values.lines[numb][field];
                     })
                   }
                }

            addVal['body'] = value;
            
            jsonData.push(JSON.stringify(addVal));

            let fields = this.props.field.connectedFields.fields;
            let mainFields = this.props.field.connectedFields.mainFields;

            client.post(this.props.field.connectedFields.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        fields.map(field => {
                            if (typeof(response[field])=== 'string') {
                                if(field === 'totalDiscount'){
                                    if(response[field] !== this.props.formData.form.initializeFromState2.values['totalDiscountRate']){
                                        showAToast(plainTranslate(this.props.formData.settings.locale,'Total discount changed to ')+response[field]+'%', 'succSettings', 'success');
                                    }
                                    this.props.change('totalDiscountRate', response[field] ? response[field] : null)
                                    
                                }else{
                                    this.props.change(line+field, response[field] ? response[field] : null)
                                }
                            }
                        })
                        if (Array.isArray(mainFields)) {
                            mainFields.map(field => {
                                this.props.change(field, response[field] ? response[field] : null)
                            })
                        }
                    })
        }

        if (this.props.field.hasOwnProperty('checkStockQuantity') && this.props.field.hasOwnProperty('disabledAvailabilityStage') &&
                ((Array.isArray(this.props.field.disabledAvailabilityStage)
                && !this.props.field.disabledAvailabilityStage.includes(this.props.formData.form.initializeFromState2.values['stageId'])))) {

            if (this.props.field.checkStockQuantity !== false) {
                let addVal = {};
                let jsonData = [];
                if (this.props.field.checkStockQuantity.hasOwnProperty('dependency')) {
                    this.props.field.checkStockQuantity.dependency.map(field => {
                        if (field == 'itId') {
                            let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];
                            addVal['itId'] = this.props.formData.form.initializeFromState2.values.lines[numb]['itId'];
                        }else if(field == 'batch') {
                            let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];
                            addVal['batch'] = this.props.formData.form.initializeFromState2.values.lines[numb]['batch'];
                        }
                         else {
                            addVal[field] = this.props.formData.form.initializeFromState2.values[field];
                        }
                    })
                }
                addVal['body'] = value;
                jsonData.push(JSON.stringify(addVal));

                let line = '';
                if (this.props.field.checkStockQuantity.isLine) {
                    let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
                    line = (this.props.input.name).substring(0, ind);

                }
                let field = this.props.field.checkStockQuantity.field;
                let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];
                let maxQuantity = (this.props.formData.form.initializeFromState2.values.lines[numb][this.props.field.checkStockQuantity.field]);

                if(this.props.formData.form.initializeFromState2.values.lines[numb]['batch'] !== null && this.props.formData.form.initializeFromState2.values.lines[numb]['batch'] !== ""){
                    maxQuantity = this.props.formData.form.initializeFromState2.values.lines[numb]['availableQuantity'];
                }
                if(this.props.formData.form.initializeFromState2.values.isPos === true && this.props.formData.form.initializeFromState2.values.lines[numb]['batch'] !== null){
                    maxQuantity = this.props.formData.form.initializeFromState2.values.lines[numb]['availableQuantity'];
                }
                let negativeQty = (this.props.formData.form.initializeFromState2.values.lines[numb]['negativeQty']);

                let orderedQty = (this.props.formData.form.initializeFromState2.values.lines[numb]['quantityOrdered']) ? true : false;

                if (typeof maxQuantity == 'undefined' ||  maxQuantity == null) {

                    client.post(this.props.field.checkStockQuantity.optionsUrl, addVal)
                            .then(response => response.data)
                            .then(response => {
                                if (response) {
                                    this.props.change(line + field, response['response'] ? response['response'] : null);
                                    this.props.change(line + 'availableQuantity', response['availableQuantity'] ? response['availableQuantity'] : null);
                                    this.props.change(line + 'negativeQty',  response['negative'] ? response['negative'] : false);
                                    this.checkQuantity(value, response['availableQuantity'] ? parseFloat(response['availableQuantity']) : parseFloat(response['response']), response['negative'], orderedQty);
                                }
                            })
                } else {
                    this.checkQuantity(value, maxQuantity, negativeQty, orderedQty);
                }
            }
        }

        if (this.props.field.hasOwnProperty('checkStockMovement') && this.props.field.checkStockMovement){
            let addVal = {};
            let jsonData = [];
            let numb = (this.props.input.name).match(/^(\D*)(\d+)/)[2];

            if (this.props.field.checkStockMovement.hasOwnProperty('dependency')) {
                this.props.field.checkStockMovement.dependency.map(field => {
                    if (field == 'lineId') {
                       
                        addVal['lineId'] = this.props.formData.form.initializeFromState2.values.lines[numb]['id'];
                    }
                     else {
                        addVal[field] = this.props.formData.form.initializeFromState2.values[field];
                    }
                })
            }
            addVal['body'] = value;
            jsonData.push(JSON.stringify(addVal));   

            clearTimeout(this.inputTimer);
            this.inputTimer = setTimeout(() => {
                client.post(this.props.field.checkStockMovement.optionsUrl, addVal)
                .then(response => response.data)
                .then(response => {
                    if (response && (value < response['quantity'])) {
                        this.props.input.onChange(this.props.formData.form.initializeFromState2.initial.lines[numb]['quantity']);
                        showAToast(plainTranslate(this.props.formData.settings.locale,'The quantity of this line cannot be changed because it has been used in a release receipt'), 'errSettings', 'error');
                    }
                })   
            }, 1000);
        }

        if (this.props.field.hasOwnProperty('calculateQty')) {
            let addVal = {};
            let jsonData = [];
            let line='';
            if (this.props.field.calculateQty.isLine) {
                   let ind = (this.props.input.name).indexOf('.'+this.props.field.id);
                   line = (this.props.input.name).substring(0, ind);
                   addVal['pos'] = line;
                }

            if (this.props.field.calculateQty.hasOwnProperty('dependency')) {
                this.props.field.calculateQty.dependency.map(field => {
                    let numb = line.match(/\d/g);
                    if (this.props.field.calculateQty.isLine){
                        addVal[field] = this.props.formData.form.initializeFromState2.values.lines[numb][field];
                    } else {
                        addVal[field] = this.props.formData.form.initializeFromState2.values[field];
                    }
                })
            }

            addVal['body'] = value;
            jsonData.push(JSON.stringify(addVal));

            let fields = this.props.field.calculateQty.fields;
            client.post(this.props.field.calculateQty.optionsUrl, addVal)
                    .then(response => response.data)
                    .then(response => {
                        fields.map(field => {
                                    this.props.change(line+field, response ? response : null)
                        })
                    })
        }
    }

    checkQuantity(value, maxQuantity, negativeQty, orderedQty = false) {
        if (!orderedQty && value > maxQuantity && negativeQty == 0) {
                if (isNaN(value)) {
                    return;
                }
                if (maxQuantity < 0) {
                    maxQuantity = 0;
                }
                this.setState({
                    value: maxQuantity
                });

                this.props.input.onChange(maxQuantity);
                showAToast(plainTranslate(this.props.formData.settings.locale,'Insufficient quantity'), 'errSettings', 'error');
            }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
        document.addEventListener('keydown', this.handleRecalculatePrice, false);
        
    }

    handleRecalculatePrice(e) {
        let name = document.activeElement.name;
        
        if(this.props.fields){
            let priceWithVat = this.props.fields.find(o => o.id === 'unitPriceWithVat');
            if ((this.props.field.id === "unitPriceOriginal" || this.props.field.id === "price") && ((e.keyCode === 191 && e.ctrlKey) || (e.keyCode === 111 && e.ctrlKey)) && this.props.input.name === name && (priceWithVat && priceWithVat.hide === false)) {
                let taxRate = this.props.formData.form.initializeFromState2.values.taxRate;
                if(!taxRate){
                    taxRate = this.props.formData.form.initializeFromState2.values.taxPercent;
                }
                let price = this.props.input.value;

                if(this.state.calculateValue){
                    price = this.state.calculateValue;
                }
                
                let newPrice = price / (1+taxRate/100);
                this.props.change(name,newPrice.toFixed(this.props.field.scale));
            }
        }
    }

    render() {
        let min = '';
        let max = '';
        const rules = this.props.field.rules;
        if (rules) {
            min = 'min' in rules ? rules.min : '';
            max = 'max' in rules ? rules.max : '';
        }

        let disabled = this.props.field.disabled;

        if(this.props.formData.form.initializeFromState2 && this.props.formData.form.initializeFromState2.values && this.props.formData.form.initializeFromState2.values.isProduction){
            let ind = (this.props.input.name).indexOf('.' + this.props.field.id);
            let key = (this.props.input.name).substring(0, ind).replace(/[^0-9]/g, '');
            let line = this.props.formData.form.initializeFromState2.values.lines[key];

            if(this.props.field.id === 'quantity' && line && line.hasOwnProperty('stageStatus') && line['stageStatus'] === 0){
                disabled = false;
            }
        }
        return <span className="form-input-field" >
                    <input type="number"
                           step="any"
                           pattern="[0-9]*"
                           className={"form-control " + (this.props.highlightField ? this.props.highlightField : '')}
                           value={this.props.input.value}
                           onChange={this.onChange}
                           min={min}
                           max={max}
                           style={{minWidth: this.props.field.width ? this.props.field.width : 'unset'}}
                           disabled={disabled}
                           tabIndex={this.props.field.tabindex ? this.props.field.tabindex : ''}
                           ref={this.setWrapperRef}
                           name={this.props.input.name}
                    />
                </span>
    }
}

const mapStateToProps = state => ({
    formData: state,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Number);
