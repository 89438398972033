import React from 'react';
import { connect } from 'react-redux';

class Percents extends React.Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const rules = this.props.field.rules;
        console.log(rules)
        this.props.input.onChange(e.target.value);
        if (rules) {
            let min = 'min' in rules ? rules.min : '';
            let max = 'max' in rules ? rules.max : '';
            if (e.target.value > max) {
                this.props.input.onChange(max); 
            }
            if (e.target.value < min) {
                this.props.input.onChange(min); 
            }
        }
    }

    render() {
        return <div className="col-xs-12 col-sm-12 no-gutter">
            <span className="form-input-field">
                <input type="number"
                       required="required"
                       className="text-right form-control percent-input"
                       value={this.props.input.value}
                       onChange={this.onChange}
                       placeholder={this.props.placeholder ? this.props.placeholder : ''}
                       disabled={this.props.field.disabled}
                       tabIndex={this.props.tabindex ? this.props.tabindex : ''}
                style={{width: '50%', minWidth: '45px', padding: '5px', borderRadius: '3px 0 0 3px'}}/>
            </span>
            <span className="input-group-addon" style={{width: 'auto', height: '40px'}}>%</span>
        </div>
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Percents);
